* {
  font-family: Inter, sans-serif !important;
}

@media (max-width: 1023px) {
  #launcher {
    display: none !important;
    pointer-events: none !important;
  }
}

.mantine-Modal-inner {
  left: 0 !important;
}